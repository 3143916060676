import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import LazyImage from '../../../HOC/lazyImage';
import './styles.scss';

const ResidenceCard = ({
  path,
  coverUrl,
  price,
  name,
  shortAddress,
  address,
  deadline,
  cypress
}) => {
  return (
    <>
      <Link href={path}>
        <a className="item-card item-card_residence" data-cy={cypress}>
          <div className="item-card-preview item-card_residence-preview">
            <LazyImage src={coverUrl} />
          </div>
          <div className="title_block">
            <div className="abs_block">
              <div className="item-card_residence-price">
                <Trans
                  i18nKey="sections.residences.from"
                  values={{
                    price: price.total.minimum.toLocaleString('ru-RU'),
                    currency: price.currency
                  }}
                >
                  <span className="item-card_residence-price-val">
                    {price.total.minimum.toLocaleString().replace(/,/g, ' ')}
                  </span>
                  <span className="item-card_residence-price-cur">
                    {price.currency}
                  </span>
                </Trans>
              </div>
            </div>
            <div className="item-card_residence-title">{name}</div>
          </div>
          <div className="item-card-params item-card_residence-params">
            <div className="item-card_residence-params-location">
              {shortAddress ? `${shortAddress}` : `${address}`}
            </div>
            {deadline && (
              <div className="item-card_residence-params-deadline">
                {deadline}
              </div>
            )}
          </div>
        </a>
      </Link>
    </>
  );
};

ResidenceCard.propTypes = {
  t: PropTypes.func,
  path: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.object,
  coverUrl: PropTypes.string,
  shortAddress: PropTypes.string,
  address: PropTypes.string,
  deadline: PropTypes.string,
  offset: PropTypes.number,
  overflow: PropTypes.bool,
  cypress: PropTypes.string
};

export default ResidenceCard;
